<template>
  <div class="productList">
    <el-button type="primary" @click="addlist">添加商品分类</el-button>
    <el-table
      :data="tableData.data"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border-bottom
      max-height="calc(100vh - 160px-42px)"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="name" label="分类名称"></el-table-column>
      <el-table-column label="分类图标">
        <template slot-scope="scope">
          <img
            style="width:40px;height:30px"
            @click="$refs.img.previewImage(scope.row.icon)"
            :src="scope.row.icon"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="sort" sortable label="排序"> </el-table-column>
      <el-table-column label="是否显示">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_show"
            active-color="#13ce66"
            inactive-color="#dcdfe6"
            @change="handleSwitch(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <!-- 弹框 -->
    <el-dialog
      :title="title"
      @close="close"
      :close-on-click-modal="false"
      :visible.sync="add"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="上级分类" prop="p_id">
          <el-cascader
            :options="options"
            :props="{ value: 'id', label: 'name', checkStrictly: true }"
            clearable
            v-model="value"
            :disabled="status"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            style="width:217px"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片" prop="icon">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            accept=".jpeg,.bmp,.png,.gif"
            :show-file-list="false"
            :http-request="uploadSectionFile"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="ruleForm.icon" :src="ruleForm.icon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch
            v-model="ruleForm.is_show"
            active-color="#13ce66"
            inactive-color="#c0c4cc"
            active-text="显示"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="ruleForm.sort" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>
<script>
import {
  product,
  getCategoryList,
  addcategory,
  // productcategory,
  PUTproductcategory
} from '../../../api/productList'
import { upload } from '../../../utile/upload'
export default {
  name: 'productList',
  data () {
    return {
      tableData: '', // 表格数据
      add: false, // 弹框标识
      status: false,
      value: [], // 收集到的上级分类数据ID
      options: [], // 上级分类数据
      title: '添加商品分类',
      page: '',
      limit: '',
      ruleForm: {
        p_id: '', // 父分类ID
        name: '', // 分类名称
        icon: '', // 分类图片路径
        is_show: true,
        sort: ''
      },
      ID: '',
      rules: {
        name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传分类名称', trigger: 'blur' }],
        p_id: [{ required: true, message: '请选择分类名称', trigger: 'blur' }]
      }
    }
  },
  watch: {
    value (val) {
      if (!this.status) {
        this.ruleForm.p_id = val[0]
      }
    }
  },
  methods: {
    // 表格中的显示按钮
    handleSwitch (row) {
      const data = {
        p_id: row.p_id,
        name: row.name,
        icon: row.icon,
        is_show: row.is_show ? 1 : 0,
        sort: row.sort
      }
      PUTproductcategory(row.id, data).then(res => {
        if (res.code === 200) {
          this.getproduct({ page: this.page, limit: this.limit })
          this.$message.success('更新成功')
        } else {
          this.$message.error(`${res.msg}`)
        }
      })
    },
    // 编辑按钮
    async handleEdit (row) {
      this.ID = row.id
      this.title = '编辑商品分类'
      this.add = true
      if (row.children && this.title === '编辑商品分类') {
        this.status = true
        this.ruleForm.p_id = row.p_id
      }
      await this.addlist()
      const array = row.path.split(',').map(item => {
        return item * 1
      })
      array.shift()
      this.value = array
      this.ruleForm.name = row.name // 分类名称
      this.ruleForm.icon = row.icon // 分类图片路径
      this.ruleForm.is_show = row.is_show
      this.ruleForm.sort = row.sort
    },
    // 添加商品分类
    addlist () {
      this.add = true
      if (this.title === '添加商品分类') {
        this.status = false
      }
      getCategoryList().then(res => {
        res.data.forEach(item => {
          item.children.forEach(item => {
            item.disabled = true
          })
        })
        this.options = res.data
      })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile (param) {
      // this.ruleForm.icon = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.icon = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 清空
    close () {
      this.title = '添加商品分类'
      this.value = []
      this.status = false
      this.ruleForm = {
        p_id: '', // 父分类ID
        name: '', // 分类名称
        icon: '', // 分类图片路径
        is_show: true,
        sort: ''
      }
    },
    // 添加分类确认按钮
    submit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm,
            is_show: this.ruleForm.is_show ? 1 : 0
          }
          if (this.title === '添加商品分类') {
            addcategory(data).then(res => {
              if (res.code === 200) {
                this.add = false
                this.close()
                this.getproduct({ page: this.page, limit: this.limit })
                this.$message.success(`${res.data}`)
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          }
          if (this.title === '编辑商品分类') {
            PUTproductcategory(this.ID, data).then(res => {
              if (res.code === 200) {
                this.add = false
                this.close()
                this.getproduct({ page: this.page, limit: this.limit })
                this.$message.success(`${res.data}`)
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.getproduct({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.getproduct({ page: val })
    },
    // 表格数据请求
    getproduct ({ page = '', limit = 10 } = {}) {
      product({ page, limit }).then(res => {
        res.data.data.forEach(item => {
          if (item.is_show === 1) {
            item.is_show = true
          } else {
            item.is_show = false
          }
          item.children.forEach(item => {
            if (item.is_show === 1) {
              item.is_show = true
            } else {
              item.is_show = false
            }
          })
        })
        this.tableData = res.data
      })
    }
  },
  mounted () {
    this.getproduct()
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-icon-star-on {
  color: red;
  font-size: 10px;
  vertical-align: text-top;
  margin-right: -6px;
}
.item {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.footers {
  text-align: right;
  margin-top: 10px;
}
.footer {
  width: 75%;
  text-align: center;
  color: red;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
