import request from './request'

// 商品分类列表 表格数据
export const product = ({ page, limit }) => {
  return request({
    url: 'admin/v1/product_category?limit=' + limit + '&page=' + page,
    method: 'GET'
  })
}
// 获取市场下的商户列表
export const getSellerByMarketId = id => {
  return request({
    url: 'admin/v1/getSellerByMarketId?market_id=' + id,
    method: 'GET'
  })
}

// 获取所有商品分类
export const getCategoryList = () => {
  return request({
    url: 'admin/v1/getCategoryList',
    method: 'GET'
  })
}
// 添加商品分类
export const addcategory = data => {
  return request({
    url: 'admin/v1/product_category',
    method: 'POST',
    data
  })
}

// // 商品分类详情
// export const productcategory = id => {
//   return request({
//     url: `admin/v1/product_category/${id}`,
//     method: 'GET'
//   })
// }

// 商品分类编辑
export const PUTproductcategory = (id, data) => {
  return request({
    url: `admin/v1/product_category/${id}`,
    method: 'PUT',
    data
  })
}

// 商品库表格
export const GETproduct = ({ page, limit }) => {
  return request({
    url: 'admin/v1/product?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 商品库新增
export const POSTproduct = data => {
  return request({
    url: 'admin/v1/product',
    method: 'POST',
    data
  })
}
// 商品库修改
export const PUTproduct = ({ data, id }) => {
  return request({
    url: 'admin/v1/product/' + id,
    method: 'PUT',
    data
  })
}

// 商品库 经营户商品分类管理表格
export const GETproductsellertypes = ({ page, limit }) => {
  return request({
    url: 'admin/v1/product_seller_types?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 商品库 店铺
export const GETShopBySellerId = id => {
  return request({
    url: 'admin/v1/getShopBySellerId?seller_id=' + id,
    method: 'GET'
  })
}

// 添加经营户商品分类管理
export const POSTproductsellertypes = data => {
  return request({
    url: 'admin/v1/product_seller_types',
    method: 'POST',
    data
  })
}
// 添加经营户商品分类管理
export const PUTproductsellertypes = (data, id) => {
  return request({
    url: 'admin/v1/product_seller_types/' + id,
    method: 'PUT',
    data
  })
}

// 经营户商品表格
export const GETproductSellers = ({ page, limit }) => {
  return request({
    url: 'admin/v1/productSellers?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 经营户商品 编辑
export const PUTproductSellers = (data, id) => {
  return request({
    url: 'admin/v1/productSellers/' + id,
    method: 'PUT',
    data
  })
}
// 经营户商品 添加
export const POSTproductSellers = data => {
  return request({
    url: 'admin/v1/productSellers',
    method: 'POST',
    data
  })
}
// 计量单位列表
export const GETunits = ({ page, limit }) => {
  return request({
    url: 'admin/v1/units?page=' + page + '&limit=' + limit,
    method: 'GET'
  })
}
// 计量单位新增
export const POSTunits = data => {
  return request({
    url: 'admin/v1/units',
    method: 'POST',
    data
  })
}
// 计量单位编辑
export const PUTunits = (data, id) => {
  return request({
    url: 'admin/v1/units/' + id,
    method: 'PUT',
    data
  })
}
// 供应商列表
export const GETsuppliers = id => {
  return request({
    url: 'admin/v1/suppliers',
    method: 'GET'
  })
}
// 根据商户获取供应商
export const GETsupplier = id => {
  return request({
    url: 'admin/v1/getSuppliersBySellerId?seller_id=' + id,
    method: 'GET'
  })
}

// 获取商户下的店铺列表
export const getShopBySellerId = id => {
  return request({
    url: 'admin/v1/getShopBySellerId?seller_id=' + id,
    method: 'GET'
  })
}
// 获取商户下的店铺列表
export const getTypeByShopId = id => {
  return request({
    url: 'admin/v1/getTypeByShopId?shop_id=' + id,
    method: 'GET'
  })
}
// 根据商品名获取商品信息
export const getProductByName = id => {
  return request({
    url: 'admin/v1/getProductByName?name=' + id,
    method: 'GET'
  })
}

// 获取对应市场，商户下的电子秤设备列表
export const getDeviceList = (sellerid, marketid) => {
  return request({
    url:
      'admin/v1/getDeviceList?seller_id=' + sellerid + '&market_id=' + marketid,
    method: 'GET'
  })
}
// 根据商户ID获取商户商品列表
export const getProductBySellerId = (marketid = '', sellerid = '') => {
  return request({
    url: `admin/v1/getProductBySellerId?market_id=${marketid}&seller_id=${sellerid}`,
    method: 'GET'
  })
}
// 新增台账
export const POSTusellerParametersits = data => {
  return request({
    url: 'admin/v1/sellerParameters',
    method: 'POST',
    data
  })
}

// 后台指导价列表
export const getproductGuidedList = data => {
  return request({
    url: 'admin/v1/productGuidedList',
    method: 'GET',
    params: data
  })
}

// 批量添加商戶商品
export const addSellerProduct = data => {
  return request({
    url: 'admin/v1/add_seller_product',
    method: 'POST',
    data
  })
}
